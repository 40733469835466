<template>
    <div>
        <!--        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" loosing-text="释放刷新" pulling-text="下拉刷新"-->
        <!--                          success-text="刷新成功">-->
        <!--        </van-pull-refresh>-->
        <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                :immediate-check="false"
                @load="onLoad">
            <div class="main_list" v-for="(item,index) in list" :key="index">
                <!--                    -->
                <div v-if="item.status === 2 || item.status === 3" class="main-list-container">
                    <div class="main-list-container-left">
                        <img src="../assets/img/copon_03.png" class="main-list-container-left-img">
                        <div class="main-list-container-left-info">
                            <span class="main-list-container-left-font">{{item.couponType == 1 || item.couponType == 3 ? '¥' : ''}}</span>
                            <span class="main-list-container-left-spacing" v-if="item.couponType !== 3">{{item.preferential}}</span>
                            <span class="main-list-container-left-spacing" v-if="item.couponType === 3">0.0</span>
                            <span class="main-list-container-left-font">{{item.couponType == 2 ? '折' : ''}}</span>
                        </div>
                        <span class="main-list-container-left-type">
                                {{item.couponType == 1 ? '现金优惠' :
                                item.couponType == 2 ?  '折扣优惠' :
                                item.couponType == 3 ? '全免优惠' : ' ' }}
                            </span>
                    </div>
                    <div class="main-list-container-center">
                        <span class="main-list-container-center-title">{{ item.couponName }}</span>
                        <span class="main-list-container-center-time">有效期至 {{item.endEffectiveTime}}</span>
                    </div>
                    <div class="main-list-container-right">
                        <img v-if="item.status === 2" src="../assets/img/copon_06.png">
                        <img v-if="item.status === 3" src="../assets/img/copon_10.png">
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
    import {Icon, List, Popup, PullRefresh} from 'vant';
    import {couponList} from "../api/interface";

    export default {
        name: "invalidCoupon",
        components: {
            [Icon.name]: Icon,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Popup.name]: Popup,
        },
        data() {
            return {
                list: [],
                finished: false,
                error: false,
                loading: false,
                total: 0,
                finishedText: "没有更多了",
                loadingText: "加载中",
                current: 1,
                size: 10,
                value: "",
                couponType: "",
                preferential: "",
            }
        },
        created() {
            this.getCouponList();
        },
        methods: {
            getCouponList() {
                let param = {
                    current: this.current,
                    size: this.size,
                    carNumber: this.$route.query.carNumber,
                    type: this.$route.query.type
                }
                couponList(param).then((res) => {
                    // 这里将拿到的数组合并到老数组，不能直接赋值，必须是合并
                    this.list = this.list.concat(res.data.data.records);
                    // this.list = res.data.data.records;
                    // 赋值当前页、总页数、总数量
                    this.current = res.data.data.current;
                    this.total = res.data.data.total;
                    this.size = res.data.data.size;

                    // 取消loading效果
                    this.loading = false;

                    // 如果当前数组的长度超过总信息条数，不再触发滚动
                    if (this.list.length >= this.total) {
                        // 加载结束，不再触发滚动加载
                        this.finished = true;
                    }
                })
            },
            onLoad() {
                this.current++;
                this.getCouponList();
            },
            onRefresh() {
                this.getCouponList();
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#fff;')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style scoped>
    .main_list {
        margin: 10px;
        background: #F9F9F9;
        border-radius: 10px;
    }

    .main-list-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px 0 10px 10px;
    }

    .main-list-container-left {
        display: flex;
        flex-direction: column;
    }

    .main-list-container-left-img {
        width: 90px;
    }

    .main-list-container-left-info {
        font-size: 22px;
        color: #FFFFFF;
        position: absolute;
        padding: 18px 21px;
        font-weight: bold;
    }

    .main-list-container-left-font {
        font-size: 15px;
        font-weight: 400;
    }

    .main-list-container-left-spacing {
        padding: 0 3px;
    }

    .main-list-container-left-type {
        font-size: 13px;
        color: #FFFFFF;
        position: absolute;
        padding: 65px 19px 0 19px;
    }

    .main-list-container-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 0 10px;
    }

    .main-list-container-center-title {
        display: flex;
        font-size: 17px;
        color: #222222;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .main-list-container-center-time {
        display: flex;
        font-size: 13px;
        color: #888888;
        font-weight: 400;
    }

    .main-list-container-right {
        display: flex;
        justify-content: center;
        align-items: center
    }

    .main-list-container-right img {
        width: 60px;
        height: 60px;
    }
</style>

